
import { defineComponent } from "vue";
//import { Field } from "vee-validate";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import ApiService from "@/core/services/ApiService";
import PhotoModal from "@/views/trainee/modal/photo.vue";
import BankModal from "@/views/trainee/modal/bank.vue";
import LicenseModal from "@/views/trainee/modal/license.vue";
import EmployeeModal from "@/views/job-placement/modal/EmployeeModal.vue";
import UserModal from "@/views/trainee/modal/user.vue";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Datatable,
    PhotoModal,
    BankModal,
    LicenseModal,
    UserModal,
    EmployeeModal,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      trainee: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableData: [
        {
          id: 1,
          name: "MOHAMMAD SAZZAD HOSEN",
          photo: "No photo",
          ref_no: "1234",
          reg_no: "1234",
          employment_status: "0",
        },
        {
          id: 2,
          name: "MD. MARUF AHMED",
          photo: "No photo",
          ref_no: "12345",
          reg_no: "12345",
          employment_status: "1",
        },
      ],
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "min-w-10px",
        },
        {
          name: "Photo",
          key: "photo",
          sortable: true,
        },
        {
          name: "Reference Number",
          key: "ref_no",
          sortable: true,
        },
        {
          name: "Registration Number",
          key: "reg_no",
          sortable: true,
        },
        {
          name: "Trainee Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Employment Status",
          key: "employment_status",
          sortable: true,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
});
