
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from "vue";
import { Field } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "trainee_photo_update",
  components: {
    Field,
  },
  data() {
    return {
      trainee: {
        photo: "",
      },
      url: "",
    };
  },
  methods: {
    traineePhoto(e) {
      this.trainee.photo = e.target.files[0];
      this.url = URL.createObjectURL(this.trainee.photo);
    },
    async formSubmit() {
      let formData = new FormData();

      formData.set("photo", this.trainee.photo);
      await ApiService.post("trainee/", formData)
        .then((response) => {
          if (response.status == 200) {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              // this.formData = {
              //   id: "",
              //   reference_number: "",
              //   trainee_name: "",
              //   gender: "",
              //   nid: "",
              //   bcn: "",
              //   date_of_birth: "",
              //   present_address: "",
              //   present_post_code: "",
              //   present_district: "",
              //   present_upazilla: "",
              //   photo: "",
              //   per_address: "",
              //   per_post_code: "",
              //   per_district: "",
              //   per_upazilla: "",
              //   home_district: "",
              //   home_upazilla: "",
              //   mobile: "",
              //   alternative_mobile: "",
              //   religion: "",
              //   ethnic_group: "",
              //   highest_class_completed: "",
              //   highest_class_completed_year: "",
              //   is_employed: "",
              //   year_of_experience: "",
              //   family_monthly_income: "",
              //   is_physically_challenged: "",
              //   challenge_remarks: "",
              //   mother_name: "",
              //   mother_education_level: "",
              //   mother_occupation: "",
              //   father_name: "",
              //   father_education_level: "",
              //   father_occupation: "",
              //   father_annual_income: "",
              //   have_family_owned_home: "",
              //   have_family_owned_land: "",
              //   number_of_siblings: "",
              // };
              this.emitter.emit("trainee-updated", true);
              //this.$router.push("/sign-up");
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
        });
    },
  },
});
